<script>
import { mapGetters } from "vuex";
import store from "@/state/store";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

import Axios from "@/config/axios";

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";

export default {
  page: {
    title: "Liste des colis",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Liste des colis",
      items: [
        {
          text: "Suivi de colis",
          href: "/",
        },
        {
          text: "Liste des colis",
          active: true,
        },
      ],
      page: 1,
      perPage: 8,
      pages: [],
      value: null,
      statuscategory: "All",
      value1: null,
      searchQuery: null,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      date: null,
      date2: null,
      defaultOptions: {
        animationData: animationData,
      },
      isStatus: null,
      isPayment: null,
    };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
    flatPickr,
  },
  computed: {
    ...mapGetters("requestTrackings", ["requests"]),
    orders() {
      return this.requests;
    },
    displayedPosts() {
      return this.paginate(this.orders);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.orderId.toLowerCase().includes(search) ||
            data.customer.toLowerCase().includes(search) ||
            data.product.toLowerCase().includes(search) ||
            data.orderDate.toLowerCase().includes(search) ||
            data.amount.toLowerCase().includes(search) ||
            data.payment.toLowerCase().includes(search) ||
            data.status.toLowerCase().includes(search)
          );
        });
      } else if (this.date !== null) {
        if (this.date !== null) {
          var date1 = this.date.split(" to ")[0];
          var date2 = this.date.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.orderDate.slice(0, 12)) >= new Date(date1) &&
            new Date(data.orderDate.slice(0, 12)) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.value !== null) {
        return this.displayedPosts.filter((data) => {
          if (data.status === this.value) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.value1 !== null) {
        return this.displayedPosts.filter((data) => {
          if (data.payment === this.value1) {
            return data;
          } else {
            return null;
          }
        });
      } else if (
        this.date !== null &&
        this.value !== null &&
        this.value1 !== null
      ) {
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.orderDate.slice(0, 12)) >= new Date(date1) &&
            new Date(data.orderDate.slice(0, 12)) <= new Date(date2) &&
            data.status === this.value &&
            data.payment === this.value1
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {},
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },

  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
  async beforeRouteEnter() {
    await store.dispatch("requestTrackings/findAll");
  },
  methods: {
    async getorders() {
      await this.$store.dispatch("requestTrackings/findAll");
    },
    onChangeStatus(e) {
      this.isStatus = e;
    },
    onChangePayment(e) {
      this.isPayment = e;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.orders.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(orders) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return orders.slice(from, to);
    },
    editdata(data) {
      let result = this.orders.findIndex((o) => o.orderId == data.orderId);
      document.getElementById("edtorderId").value = this.orders[result].id;
      document.getElementById("edtdelivered").value =
        this.orders[result].status;
    },
    updateorder() {
      let result = this.orders.findIndex(
        (o) => o.id == document.getElementById("edtorderId").value
      );
      this.orders[result].status =
        document.getElementById("edtdelivered").value;

      document.getElementById("edtclosemodal").click();

      Axios.put(
        `/admin/orders/${document.getElementById("edtorderId").value}`,
        this.orders[result]
      )
        .then(() => {
          this.getorders();
        })
        .catch((er) => {
          console.log(er);
        });
    },
    deletedata(event) {
      Swal.fire({
        title: "Êtes-vous certain ?",
        text: "Cette opération est irréversible !",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, supprimer !",
      }).then((result) => {
        if (result.value) {
          this.orders.splice(this.orders.indexOf(event), 1);
          Axios.delete(`/admin/orders/${event.id}`)
            .then(() => {
              this.getorders();
            })
            .catch((er) => {
              console.log(er);
            });
          Swal.fire("Supprimé !", "La commande a été effacée.", "success");
        }
      });
    },
    SearchData() {
      this.resultQuery;
      // var isstatus = document.getElementById("idStatus").value;
      //   var payment = document.getElementById("idPayment").value;
    },
    changecategory(data) {
      console.log(data);
      this.statuscategory = data;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Historique</h5>
              <div class="flex-shrink-0">
                <router-link
                  type="button"
                  class="btn btn-success add-btn"
                  to="/add-request"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Enregistrer un
                  colis
                </router-link>
                <!-- <button type="button" class="btn btn-info ms-1">
                  <i class="ri-file-download-line align-bottom me-1"></i>
                  Importer
                </button> -->
              </div>
            </div>
          </div>
          <div
            class="card-body border border-dashed border-end-0 border-start-0"
          >
            <form>
              <div class="row g-3">
                <div class="col-xxl-5 col-sm-6">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search"
                      placeholder="Rechercher l'ID de commande, le client, l'état de la commande ou quelque chose..."
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-2 col-sm-6">
                  <div>
                    <flat-pickr
                      placeholder="Select date"
                      v-model="date"
                      :config="config"
                      class="form-control flatpickr-input"
                      id="demo-datepicker"
                    ></flat-pickr>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-2 col-sm-4">
                  <div>
                    <Multiselect
                      class="form-control"
                      v-model="value"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="true"
                      @input="onChangePayment"
                      :options="[
                        { value: '', label: 'Statut' },
                        { value: 'Tous', label: 'Tous' },
                        { value: 'PENDING', label: 'En cours' },
                        { value: 'COMPLETE', label: 'Terminé' },
                      ]"
                    />
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-3 col-sm-4">
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary w-100"
                      @click="SearchData"
                    >
                      <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      Filtres
                    </button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body pt-0">
            <div>
              <ul
                class="nav nav-tabs nav-tabs-custom nav-success mb-3"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active All py-3"
                    data-bs-toggle="tab"
                    id="All"
                    href="#home1"
                    role="tab"
                    @click="changecategory('All')"
                    aria-selected="true"
                  >
                    <i class="ri-store-2-fill me-1 align-bottom"></i> Tous les
                    colis
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-3 Pending"
                    data-bs-toggle="tab"
                    id="Pending"
                    href="#pending"
                    role="tab"
                    @click="changecategory('PENDING')"
                    aria-selected="false"
                  >
                    <i class="ri-truck-line me-1 align-bottom"></i>
                    En cours
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-3 Complete"
                    data-bs-toggle="tab"
                    id="Complete"
                    href="#complete"
                    role="tab"
                    @click="changecategory('COMPLETE')"
                    aria-selected="false"
                  >
                    <i class="ri-truck-line me-1 align-bottom"></i>
                    Terminé
                  </a>
                </li>
              </ul>

              <div class="table-responsive table-card mb-1">
                <table class="table table-nowrap align-middle" id="orderTable">
                  <thead class="text-muted table-light">
                    <tr class="text-uppercase">
                      <th scope="col" style="width: 25px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                          />
                        </div>
                      </th>
                      <th class="sort" data-sort="id">Code de suivi</th>
                      <th class="sort" data-sort="customer_name">Client</th>
                      <th class="sort" data-sort="product_name">Colis</th>
                      <th class="sort text-center" data-sort="date">
                        Date d'enregistrement
                      </th>
                      <!-- <th class="sort" data-sort="amount">Montant</th> -->
                      <th class="sort" data-sort="status">Statut</th>
                      <th class="sort" data-sort="city">Actions</th>
                    </tr>
                  </thead>
                  <tbody
                    class="list form-check-all"
                    v-for="(data, index) of resultQuery"
                    :key="index"
                  >
                    <tr
                      v-if="
                        statuscategory == 'All' || statuscategory == data.status
                      "
                    >
                      <!-- <div v-if="statuscategory=='All' || statuscategory==data.status"> -->
                      <th scope="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="chk_child"
                            value="option1"
                          />
                        </div>
                      </th>
                      <td class="id">
                        <router-link
                          :to="'/request-details/' + data.id"
                          class="fw-medium link-primary"
                        >
                          #{{ data.code }}
                        </router-link>
                      </td>
                      <td class="customer_name">
                        {{ data.client_infos.fullname }}
                      </td>
                      <td class="product_name" v-html="data.title"></td>
                      <td class="date text-center">
                        {{ data.registeredDate }}
                      </td>
                      <!-- <td class="amount">{{ data.amount }}FCFA</td> -->
                      <td class="status">
                        <span
                          class="badge text-uppercase"
                          :class="{
                            'badge-soft-warning': data.status == 'PENDING',
                            'badge-soft-dark': data.status == 'COMPLETE',
                          }"
                        >
                          {{ data.status }}
                        </span>
                      </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="View"
                          >
                            <router-link
                              :to="'/request-details/' + data.id"
                              class="text-primary d-inline-block"
                            >
                              <i class="ri-eye-fill fs-16"></i>
                            </router-link>
                          </li>
                          <!-- <li
                            class="list-inline-item edit"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Edit"
                          >
                            <a
                              class="text-primary d-inline-block edit-item-btn"
                              data-bs-toggle="modal"
                              href="#EditModal"
                              @click="editdata(data)"
                            >
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Remove"
                          >
                            <a
                              class="text-danger d-inline-block remove-item-btn"
                              @click="deletedata(data)"
                            >
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li> -->
                        </ul>
                      </td>
                      <!-- </div> -->
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Pardon! Aucun résultat trouvé</h5>
                    <p class="text-muted">
                      Nous avons recherché plus de 150 commandes Nous n'en avons
                      trouvé aucune commandes pour votre recherche.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev disabled"
                    href="#"
                    v-if="page != 1"
                    @click="page--"
                  >
                    Precedent
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li
                      v-for="(pageNumber, index) in pages.slice(
                        page - 1,
                        page + 5
                      )"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                    >
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="#"
                    @click="page++"
                    v-if="page < pages.length"
                  >
                    Suivant
                  </a>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="EditModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabels"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Editer une commande
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="editform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-3 d-none" id="modal-id">
                        <label for="orderId" class="form-label">ID</label>
                        <input
                          type="text"
                          pattern="\d*"
                          id="edtorderId"
                          class="form-control"
                          placeholder="ID"
                          maxlength="3"
                          readonly
                        />
                      </div>

                      <div>
                        <label for="delivered-status" class="form-label"
                          >Statut de livraison</label
                        >
                        <select
                          class="form-control"
                          data-trigger
                          name="delivered-status"
                          id="edtdelivered"
                        >
                          <option value="">Statut de livraison</option>
                          <option value="PENDING">En attente</option>
                          <option value="COMPLETE">Terminé</option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                          id="edtclosemodal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="add-btn"
                          @click="updateorder"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
